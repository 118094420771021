import { useState } from "react";
import "./App.css";
import { Button, ChakraProvider, Flex, Image, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";

const NavBar = () => {
  return (
    <Flex as="nav" align="center" justify="space-between" padding="1rem" backgroundColor="white" color="white" flexWrap="wrap" justifyContent={"center"}>
      <Flex flexDir={"row"}>
        <Image src="/BITS_Pilani_Logo.png" alt="BITS Pilani Logo" boxSize={{ base: "3rem", md: "4rem", xl: "6rem" }} padding={{ base: "2px", md: "4px", xl: "6px" }} />
        <Image src="/Goa_Campus.png" alt="BITS Pilani Logo" height={{ base: "3rem", md: "4rem", xl: "6rem" }} padding={{ base: "2px", md: "4px", xl: "6px" }} />
      </Flex>
    </Flex>
  );
};

function App() {
  const toISO = (date) => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().slice(0, -8);

  const current_time = new Date();
  const waves_start = new Date("2024/11/8");

  const [from, setFrom] = useState(toISO(waves_start));
  const [to, setTo] = useState(toISO(current_time));

  return (
    <ChakraProvider>
      <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
        <NavBar />
        <InputGroup alignItems={"center"} justifyContent={"center"}>
          <InputLeftAddon width={"5%"}>From</InputLeftAddon>
          <Input placeholder="From" size="md" type="datetime-local" width={"25%"} onChange={(e) => setFrom(e.target.value)} value={from} min={toISO(waves_start)} max={toISO(current_time)} />
        </InputGroup>
        <InputGroup alignItems={"center"} justifyContent={"center"} mt={"1%"}>
          <InputLeftAddon width={"5%"} justifyContent={"center"}>
            To
          </InputLeftAddon>
          <Input placeholder="To" size="md" type="datetime-local" width={"25%"} onChange={(e) => setTo(e.target.value)} value={to} min={toISO(waves_start)} max={toISO(current_time)} />
        </InputGroup>
        <Button
          colorScheme="green"
          width={"15%"}
          mt={"1%"}
          onClick={() => {
            window.location.href = `https://bits-payment-interface.azurewebsites.net/data/${from}&${to}`;
            // window.location.href = `http://localhost:3001/data/${from}&${to}`;
          }}
        >
          Download Report
        </Button>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
